nav {
    height: 70px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index: 100;
    position: fixed;
    width: 100%;
    background: #0a192f;
  }
  .link {
    text-decoration: none;
    display: flex;
    color: #64ffda;
    align-items: center;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: .8rem;
  }
  .menu-bars{
    font-size: 1rem;
  }
  .mobile-menu-icon {
    display: none;
  }
  .icons {
    display: flex;
    align-items: center;
  }
  .icon-tabler {
    margin-right: 5px;
  }
  .github-icon {
    color: #64ffda;
  }
  .menu-items {
    display: flex;
    align-items: center;
  }
  .logo{
    margin-right: 0.5rem;
  }
  .ide{
    margin-top: 0;
    margin-left: 5rem;
  }
  
  @media screen and (max-width: 768px) {
    .menu-items,
    .signup-button,
    .github-corner {
      display: none;
    }
    .mobile-menu-icon {
      display: block;
      color: #64ffda;
      display: flex;
      align-items: center;
      font-size: 4vh;
      cursor: pointer;
      top: 0;
      right: 0;
    }
    .menu-bars{
      margin-left: 0;
      padding-left: 0;
    }
    .github-corner {
      position: fixed;
      top: 0;
      z-index: 99999;
      right: 0px;
    }
    .github-corner:hover{
      color: #64ffda;
    }
  }
  