.home-section {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-top: 3rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    /* background-color: #0a192f; */
}
.home-bar{
    padding-top: 5rem;
    display: flex;
    flex: 1 1 1%;
    flex-direction: row;
}
.image img{
    height: 18rem;
    margin-left: 2rem;
    margin-top: 5rem;
}
@media screen and (max-width: 768px) {
    .home-bar{
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
    }
    .image img{
        height: 18rem;
        margin-left: 0;
        margin-top: 0;
    }
}

@media screen and (max-width:1024px) {
    .home-section {
        padding-left: 0;
        padding-right: 0;
    }
    /* .home-section {
        padding-top: 10rem;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 0.25rem;
        padding-right: 0;
    } */
    /* .home-bar{
        padding-top: 0rem;
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
    }
    .image{
        height: 18rem;
        margin-left: 20px;
        margin-top: 3rem;
    } */
}