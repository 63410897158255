*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background-color: #0a192f;
  color: #ccd6f6;
}
section{
  background-color: #0a192f;
}
h3.ptr{
  width: 60vw;
}

@media screen and (max-width:768px) {
  h3.ptr{
    width: 90vw;
  }
}