.courses-section {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
    padding-top: 5rem;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 5rem;
}

.resource {
    padding-top: 0rem;
    display: flex;
    flex: 1 1 1 1%;
    flex-direction: row;
    justify-content: space-between;
}

.rv {
    border: 2px solid orange;
    color: #ccd6f6;
    border-radius: 5px;
    padding: 1rem;
    margin: 1rem;
}

button.rv-button {
    margin-top: 20px;
    padding: 5px;
    font-size: 1rem;
}

@media screen and (max-width: 768px) {

    .resource {
        padding-top: 0rem;
        display: flex;
        flex-direction: column;
    }

    .rv {
        border: 2px solid orange;
        color: #ccd6f6;
        border-radius: 5px;
        padding: 1rem;
        margin: 1rem;
    }

}