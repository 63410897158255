.contact-section {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.section__title{
  margin-top: 7rem;
  color: #64ffda;
}

.contact__container {
  grid-template-columns: 4fr 8fr;
  column-gap: 1.875rem;
}

.contact__title {
  font-size: var(--h3-font-size);
  margin-bottom: 1rem;
  margin-top: 2rem;
  background-color: #112240;
}
.contact__details{
  margin-bottom: 1rem;
  margin-left: 1rem;
}
.contact__form-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5rem;
}

.contact__form-div {
  position: relative;
  margin-bottom: 1.875rem;
  height: 3.5rem;
}

.contact__form-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: var(--shadow);
  background-color: aliceblue;
  color: #000;
  border: none;
  outline: none;
  border-radius: 1.5rem;
  padding: 0.625rem 1.875rem;
  z-index: 1;
}

.contact__form-area {
  height: 10rem;
}

.contact__form-area textarea {
  resize: none;
}
button.button{
  margin-top: 1rem;
  margin-bottom: 5rem;
}

@media screen and (max-width: 1024px) {
  .contact__container {
    grid-template-columns: repeat(2, 330px);
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .contact__container {
    grid-template-columns: 310px;
    row-gap: 1.875rem;
  }
  .section__title{
    margin-left: 1rem;
  }
  .contact__form{
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .contact__info {
    text-align: center;
  }
  .contact__title{
    padding: 1rem;
  }
}