.footer-section {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-height: 40vh;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding-left: 1.25rem;
    padding-right: 1.25rem; */
    border-top: 1px solid orange;
}

.footer-container {
    display: flex;
    flex: 1 1 1%;
    background-color: #0a192f;
    color: white;
}

.footer-left {
    margin-top: 2rem;
    margin-left: 2rem;
}

.footer-middle {
    display: flex;
    flex: 1 1 1%;
    justify-content: space-between;
    margin-left: 8rem;
    margin-right: 8rem;
    margin-top: 2rem;
}

.footer-right {
    margin-top: 2rem;
    margin-right: 3rem;
}

.contact {
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-right: 2rem;
}

.items {
    line-height: 2.2rem;
    padding-top: 0.5rem;
}

.items p {
    cursor: pointer;
}

.items p {
    cursor: pointer;
}

.items p:hover {
    cursor: pointer;
    color: orange;
}

.logo {
    height: 50px;
}

.links {
    display: flex;
    column-gap: 1.75rem;
    margin: 1.5rem 0;
}
.links i:hover{
    color: orange;
}

.home__social-link {
    color: var(--title-color);
    font-size: 1.3rem;
    transition: .3s;
}

.home__social-link:hover {
    color: hsl(43, 100%, 68%);
}

.footer-link {
    margin-top: 2rem;
    text-decoration: none;
    display: flex;
    color: white;
    height: 3rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: #64ffda;
}

.footer-font {
    margin-left: 1rem;
}

.call {
    margin-bottom: 0.5rem;
}
.last{
    display: none;
}
.copyright{
    margin-top: 1rem;
}
.logo{
    margin-top: .5rem;
    margin-left: 0.5rem;
    text-decoration: none;
    display: flex;
    color: white;
    height: 3rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: #64ffda;
}

@media screen and (max-width: 768px) {
    .footer-section {
        width: 100%;
        min-height: 40vh;
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        /* padding-left: 1.25rem;
        padding-right: 1.25rem; */
        border-top: 1px solid orange;
    }
    .product{
        font-size: 0.9rem;
        font-weight: bolder;
    }
    .items {
        line-height: 1.5rem;
        font-size: 0.7rem;
    }
    
    .footer-container {
        display: flex;
        flex-direction: column;
    }
    .footer-left {
        display: none;
    }
    
    .footer-middle {
        display: flex;
        flex: 1 1 1%;
        justify-content: space-between;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 2rem;
    }
    
    .footer-right {
        margin-top: 2rem;
        margin-right: 0rem;
        margin-left: 5rem;
    }
    
    .contact {
        margin-top: 2rem;
        margin-bottom: 1rem;
        margin-right: 2rem;
    }
    .last{
        color: #64ffda;
        display: inline;
        margin-bottom: 1rem;
        margin-top: 2rem;
        margin-left: 5rem;
    }
}