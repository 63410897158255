.WebDes-section {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    min-height: 70vh;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.WebDes-bar{
    /* border: 2px solid orange; */
    border-radius: 10px;
    background-color: #112240;
    padding: 3rem;
    display: flex;
    /* flex-direction: column; */
}
.me{
    margin-left: 20px;
}
.about-me{
    background-color : #0a192f;
    border-radius: 10px;
    padding: 1rem;
    /* border: 5px solid orange; */
    text-align: center;
    margin-left: 2rem;
    color: #ffffff;
    width: 35vw;
}
.title-me{
    font-size: 35px;
    font-weight: bold;
    /* background-color: #112240; */
}
.achieve{
    margin-top: 20px;
    text-align: left;
    margin-left: 1rem;
    font-weight: bold;
    /* background-color: #112240; */
}
.web-desc{
    margin-top: 20px;
    text-align: left;
    margin-left: 1rem;
    /* background-color: #112240; */
}

.letter{
    width: 98.5vw;
    height: 30vh;
    background: #112240;
    display: flex;    
    flex: 1 1%;
}
.left{
    margin-left: 10rem;
    margin-top: 2rem;
    background-color: #112240;
}
.right{
    margin-left: 10rem;
    margin-top: 2rem;
    background-color: #112240;
}
.head-left{
    font-size: 30px;
    color: white;
    background-color: #112240;
}
.head-right{
    font-size: 30px;
    color: white;
    background: #112240;
}
strong{
    background-color: #112240;
}
span{
    background-color: #112240;
}
.langs{
    color: white;
    font-size: 20px;
    margin-top: 3rem;
    background-color: #112240;
}
button{
    border: 2px solid #66ffda;
    color: #66ffda;
    margin-top: 50px;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
}
button:hover{
    color: white;
    background-color: #112240;
}


@media screen and (max-width: 768px) {
    .WebDes-bar{
        border-radius: 10px;
        background-color: #112240;
        padding: rem;
        display: flex;
        flex-direction: column;
    }.me{
        margin-left: 30px;
        margin-top: 1rem;
        background-color: #112240;
    }
    .about-me{
        border-radius: 10px;
        padding: 0rem;
        text-align: center;
        margin-left: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: #ffffff;
        width: 90vw;
    }
    .title-me{
        font-size: 20px;
        font-weight: bold;
    }
    .achieve{
        margin-top: 20px;
        text-align: left;
        margin-left: 1rem;
        font-weight: bold;
    }
    .web-desc{
        margin-top: 20px;
        text-align: left;
        margin-left: 1rem;
        margin-bottom: 1rem;
    }
    
    .letter{
        width: 100vw;
        height: 50vh;
        display: inline; 
        flex-direction: column;   
    }
    .left{
        margin-left: 0rem;
        margin-top: 2rem;
        background-color: #112240;
    }
    .right{
        margin-left: 0rem;
        margin-top: 0rem;
        background-color: #112240;
    }
    .head-left{
        font-size: 1.5rem;
        color: white;
        background-color: #112240;
    }
    .head-right{
        font-size: 1.5rem;
        margin-top: 2rem;
        color: white;
        background: #112240;
        height: 5rem;
    }
    strong{
        background-color: #112240;
    }
    span{
        background-color: #112240;
    }
    .langs{
        color: white;
        font-size: 20px;
        margin-top: 1rem;
        background-color: #112240;
    }
}

@media screen and ( max-width:1024px) {
    .WebDes-section {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
    .WebDes-bar{
        padding: 0rem;
        display: flex;
        flex-direction: column;
    }
    .image{
        height: 18rem;
        margin-left: 20px;
        margin-top: 3rem;
    }
}