.sheet-section {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-height: 50vh;
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.sheet-desc {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    width: 50%;
    color: #ccd6f6;
    margin-top: 1rem;
    margin-left: 2rem;
}
.sheet-head{
    margin-bottom: 1rem;
}
.sheetdesc {
    font-size: 1rem;
    margin-top: 2rem;
    color: #a8b2d1;
}

.sheets {
    display: flex;
    flex: 0 0 40%;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-bottom: 5rem;
}

.s1 {
    border: 2px solid orange;
    border-radius: 5px;
    padding: 2rem;
    margin: 2rem;
}
.s1:hover{
    border-color: rgb(122, 80, 2);
}
@media screen and (max-width: 768px) {
    .sheet-section {
        padding-left: 0rem;
        padding-right: 0rem;
    }
    .sheet-desc{
        margin-top: 0rem;
        margin-left: 0rem;
        padding: 1rem;
        width: 100%;
    }
    .sheetdesc{
        margin-top: 1rem;
        font-size: 0.8rem;
    }
    .sheets{
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .s1{
        padding: 1rem;
        margin: 1rem;
    }
    button.sheet-btn{
        margin-top: 0.7rem;
    }
    
}