.hero {
    margin-top: 4rem;
}

.hero-div {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 0rem;
    margin-bottom: 0.25rem;
}

.heading {
    font-size: 50px;
    color: #ccd6f6;
    line-height: 1.25;
    margin-bottom: 0;
}

.heading-div {
    margin-bottom: 0;
}

.title {
    margin-top: 0;
    font-size: 3.5rem;
    line-height: 2.5rem;
    margin-left: 0.25rem;
    color: #20c863;
    background-clip: text;
}

.description {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 0;
    font-weight: 300;
    color: #a8b2d1;
}

@media (max-width: 768px) {
    .hero {
        margin-top: 0;
    }

    .hero-div {
        text-align: left;
    }
}

@media (max-width: 640px) {
    .hero {
        margin-top: 0;
        margin-left: 1rem;
    }

    .heading {
        font-size: 40px;
        line-height: 1.25;
        margin-bottom: 0;
    }

    .title {
        margin-top: 0;
        font-size: 2.8rem;
        line-height: 2.5rem;
        margin-left: 0rem;
    }
}