.App {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

table {
  border: 2px solid orange;
  width: 800px;
  height: 200px;
}

th {
  border-bottom: 1px solid;
}

td {
  text-align: center;
}

.question {
  text-align: start;
  padding-left: 0.5rem;
  width: 40%;
  font-size: 0.8rem;
}
.index{
  color: #ffffff;
  width: 2%;
  font-size: 0.7rem;
}

h3 {
  color: orange;
  padding: 1rem;
  background-color: aliceblue;
  /* border: 2px solid alic; */
  border-radius: 0.5rem;
  margin: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  table {
    width: 350px;
    scroll-behavior: auto;
  }

  th {
    font-size: 0.5rem;
  }

  h3 {
    padding: 0;
    margin: 0;
  }
  .question{
    width: 50%;
    font-size: 0.8rem;
  }
}