.paths-section {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
    padding-top: 5rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 5rem;
}

.main-head {
    background-color: #112240;
    text-align: center;
    padding: 1.5rem;
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
    border-radius: 10px;
}
.topics {
    margin-top: 3rem;
}

.topics h3 {
    color: orange;
    border: 2px cyan;
    padding: 1rem;
    background-color: aliceblue;
    /* border: 2px solid alic; */
    border-radius: 0.5rem;
    margin: 1rem;
    cursor: pointer;
}

.topics i {
    color: orange;
    background-color: aliceblue;
}
.upper p{
    margin: 1rem;
}
